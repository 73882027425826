import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as registrationDataAction from '../../reducers/registrationDataAction';
import Spinner from '../Spinner/Spinner';
import { AppState, DateEntry } from '../../type/Type';
import { RegistrationData } from '../../type/Type';
import {
    Button,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Select,
    MenuItem,
    IconButton,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    FormControl,
} from '@material-ui/core';
import requireAuth from '../../requireAuth';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { Box } from '@material-ui/core'; // Import Box component

class FinanceModule extends Component<any, any> {
    constructor(props: any) {
        super(props);

        this.state = {
            registrationData: [],
            isLoading: true,
            apiLoading: false,
            successMessage: '',
            errorMessage: '',
            editedData: {},
            currentPage: 1, // Current page number
            recordsPerPage: 10, // Number of records per page
            editingId: null, // ID of the currently edited item
            isDialogOpen: false, // For controlling the dialog box
            isSuccessDialogOpen: false, // New state property
            isErrorDialogOpen: false, // New state property          
            searchQuery: '', // New state property  
            searchColumn: 'orgId', // Default search column            
            sortColumn: 'orgId', // Default sorting column 
            sortOrder: 'asc', // Default sorting order
            validationErrors: {},
            isDeleteDialogOpen: false,
            deleteConfirmationInput: '',
        };

        this.syncRegistrationData = this.syncRegistrationData.bind(this);
        this.deleteRegistrationData = this.deleteRegistrationData.bind(this);
        this.updateRegistrationData = this.updateRegistrationData.bind(this);
        this.handleDropdownChange = this.handleDropdownChange.bind(this);
        this.changePage = this.changePage.bind(this);
        this.handleEditButtonClick = this.handleEditButtonClick.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.handleDialogClose = this.handleDialogClose.bind(this);
        this.handleSuccessDialogClose = this.handleSuccessDialogClose.bind(this); // Bind new method
        this.handleErrorDialogClose = this.handleErrorDialogClose.bind(this); // Bind new method
        this.handleSearchChange = this.handleSearchChange.bind(this); // Bind new method
        this.handleSort = this.handleSort.bind(this); // Bind new method
        this.handleDeleteDialogOpen = this.handleDeleteDialogOpen.bind(this);
        this.handleDeleteDialogClose = this.handleDeleteDialogClose.bind(this);
        this.handleDeleteConfirmationChange = this.handleDeleteConfirmationChange.bind(this);
        this.confirmDelete = this.confirmDelete.bind(this);

    }

    handleSort(column) {
        const { sortColumn, sortOrder } = this.state;
        const newSortOrder = sortColumn === column && sortOrder === 'asc' ? 'desc' : 'asc';
        this.setState({
            sortColumn: column,
            sortOrder: newSortOrder,
        });
    }

    handleSearchChange(event) {
        this.setState({ searchQuery: event.target.value });
    }

    changePage(newPage: number) {
        this.setState({ currentPage: newPage });
    }

    exportTableToExcel() {
        const { registrationData } = this.state;

        // Prepare the data for Excel
        const data = registrationData.map((item) => ({
            'Org ID': item.orgId,
            'First Name': item.firstName,
            'Last Name': item.lastName,
            'Mobile Number': item.mobileNumber,
            'Hub Amount': item.hubAmount,
            Dates: item.dates.map((d) => `${d.value} (Count: ${d.count})`).join(', '),
            'Hub Comment': item.hubComment,
            'Final Hub Amount': item.finalHubAmount !== null ? item.finalHubAmount : '',
            'Zabihat Count': item.zabihatCount,
            'Zabihat Months': item.zabihatMonths.join(', '),
            'Zabihat Target': item.zabihatTarget,
            'Payment Option': item.paymentOption,
            'Finance Comment': item.financeComment,
            'Registration Follow Up By': item.registrationFollowUpBy,
            'Registration Follow Up Status': item.registrationFollowUpStatus,
            'Tally Updated': item.tallyUpdated ? 'Yes' : 'No',
            'Update IT': item.updateIT ? 'Yes' : 'No',
            'Registration Accepted': item.registrationAccepted ? 'Yes' : 'No',

        }));

        // Convert the data to a worksheet
        const worksheet = XLSX.utils.json_to_sheet(data);

        // Create a new workbook and append the worksheet
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Registration Data');

        // Write the workbook and save it as a file
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
        saveAs(blob, 'RegistrationData.xlsx');
    }

    async fetchExistingData() {
        try {
            this.setState({ isLoading: true });
            await this.props.getRegistrationData();
            this.setState({
                registrationData: this.props.registrationData,
                isLoading: false,
            });
        } catch (error) {
            console.error('Error fetching registration data:', error);
            this.setState({ isLoading: false });
        }
    }

    async syncRegistrationData() {
        this.setState({ apiLoading: true });
        try {
            const response = await this.props.syncRegistrationData();
            if (response.success) {
                this.setState({ successMessage: 'Data synced successfully.' });
                await this.fetchExistingData();
            } else {
                this.setState({ errorMessage: 'Failed to sync data.' });
            }
        } catch (error) {
            console.error('Error syncing data:', error);
            this.setState({ errorMessage: 'An error occurred while syncing data.' });
        } finally {
            this.setState({ apiLoading: false });
        }
    }

    deleteRegistrationData(orgId: string) {
        this.props.deleteRegistrationData(orgId, () => {
            this.setState({ successMessage: 'Data deleted successfully.' });
            this.fetchExistingData();
        });
    }

    handleDeleteDialogOpen(orgId) {
        this.setState({ isDeleteDialogOpen: true, deleteId: orgId, deleteConfirmationInput: '' });
    }

    handleDeleteDialogClose() {
        this.setState({ isDeleteDialogOpen: false, deleteConfirmationInput: '' });
    }

    handleDeleteConfirmationChange(event) {
        this.setState({ deleteConfirmationInput: event.target.value });
    }

    confirmDelete() {
        if (this.state.deleteConfirmationInput === 'delete') {
            this.deleteRegistrationData(this.state.deleteId);
            this.handleDeleteDialogClose();
        }
    }


    updateRegistrationData(updatedData: RegistrationData) {
        this.props.updateFinanceRegistrationData(
            updatedData,
            (response) => {
                if (response === "{'result': 'Success'}") {
                    this.setState({
                        successMessage: 'Data updated successfully.',
                        isSuccessDialogOpen: true,
                    });
                } else {
                    console.error('Unexpected result in success callback:', response);
                    this.setState({
                        errorMessage: response || 'Unexpected response.',
                        isErrorDialogOpen: true,
                    });
                }
                this.fetchExistingData();
            },
            (error) => {
                console.error('Error callback triggered:', error);
                this.setState({
                    errorMessage: error.message || 'Failed to process the registration data.',
                    isErrorDialogOpen: true,
                });
            }
        );
    }

    handleSuccessDialogClose() {
        this.setState({ isSuccessDialogOpen: false, successMessage: '' });
    }

    handleErrorDialogClose() {
        this.setState({ isErrorDialogOpen: false, errorMessage: '' });
    }


    handleDropdownChange(event: React.ChangeEvent<{ value: unknown }>, orgId: string) {
        const value = event.target.value === 'true'; // Convert "true"/"false" string to boolean
        this.setState((prevState) => ({
            editedData: {
                ...prevState.editedData,
                [orgId]: value,
            },
        }));
    }

    handleEditButtonClick(id: string) {
        this.setState({ editingId: id, isDialogOpen: true, editedData: this.state.registrationData.find((data) => data.orgId === id) || {} });
    }

    handleInputChange(event, field) {
        const value = event.target.value;
        this.setState((prevState) => {
            let updatedData = {
                ...prevState.editedData,
                [field]: value
            };

            if (field === 'zabihatCount') {
                // Ensure zabihatMonths array length matches the zabihatCount
                const zabihatCount = parseInt(value, 10);
                if (Array.isArray(updatedData.zabihatMonths)) {
                    updatedData.zabihatMonths = updatedData.zabihatMonths.slice(0, zabihatCount);
                } else {
                    updatedData.zabihatMonths = this.state.registrationData.find((data) => data.orgId === this.state.editingId).zabihatMonths.slice(0, zabihatCount);
                }
            }

            return {
                editedData: updatedData,
            };
        });
    }

    handleFormSubmit(event: React.FormEvent) {
        event.preventDefault();
        const { editingId, editedData, registrationData } = this.state;

        if (this.validateForm()) {

            const updatedData = {
                ...registrationData.find((data) => data.orgId === editingId),
                registrationAccepted: editedData.registrationAccepted,
                finalHubAmount: editedData.finalHubAmount,
                financeComment: editedData.financeComment,
                registrationFollowUpBy: editedData.registrationFollowUpBy,
                registrationFollowUpStatus: editedData.registrationFollowUpStatus,
                tallyUpdated: editedData.tallyUpdated,
                updateIT: editedData.updateIT,
                dates: editedData.dates || registrationData.find((data) => data.orgId === editingId).dates,
                paymentOption: editedData.paymentOption || registrationData.find((data) => data.orgId === editingId).paymentOption,
                hubAmount: editedData.hubAmount || registrationData.find((data) => data.orgId === editingId).hubAmount,
                hubComment: editedData.hubComment || registrationData.find((data) => data.orgId === editingId).hubComment,
                zabihatCount: editedData.zabihatCount || registrationData.find((data) => data.orgId === editingId).zabihatCount,
                zabihatMonths: editedData.zabihatMonths || registrationData.find((data) => data.orgId === editingId).zabihatMonths,
            };

            // Ensure zabihatMonths is an array
            updatedData.zabihatMonths = Array.isArray(updatedData.zabihatMonths) ? updatedData.zabihatMonths : [];

            this.updateRegistrationData(updatedData);
            this.setState({ editingId: null, editedData: {}, isDialogOpen: false });
        }
        else {
            console.log('Form contains validation errors. Please fix them before submitting.');

        }
    }

    validateForm() {
        const { editedData } = this.state;
        const errors: Partial<Record<keyof RegistrationData, string>> = {};

        if (editedData.zabihatCount > 0) {
            const invalidMonths = editedData.zabihatMonths.filter(month => !month || month === '');

            if (invalidMonths.length > 0 || editedData.zabihatMonths.length !== editedData.zabihatCount) {
                errors.zabihatMonths = 'Please select the required number of zabihat months.';
            }
        }

        this.setState({ validationErrors: errors });
        return Object.keys(errors).length === 0;
    }

    handleMonthChange(event, index) {
        const { value } = event.target;
        this.setState((prevState) => {
            const updatedMonths = Array.isArray(prevState.editedData.zabihatMonths)
                ? [...prevState.editedData.zabihatMonths]
                : [...this.state.registrationData.find((data) => data.orgId === this.state.editingId).zabihatMonths];
            updatedMonths[index] = value;

            return {
                editedData: {
                    ...prevState.editedData,
                    zabihatMonths: updatedMonths,
                },
            };
        });
    }


    handleDialogClose() {
        this.setState({ isDialogOpen: false, editedData: {}, editingId: null });
    }

    async componentDidMount() {
        await this.fetchExistingData();
    }

    resetSearch = () => {
        this.setState({
            searchColumn: 'orgId',
            searchQuery: '',
        });
    };

    render() {
        const {
            registrationData,
            apiLoading,
            isLoading,
            successMessage,
            errorMessage,
            editedData,
            currentPage,
            recordsPerPage,
            editingId,
            isDialogOpen,
            isSuccessDialogOpen,
            isErrorDialogOpen,
            searchQuery,
            sortColumn,
            sortOrder,
        } = this.state;

        // Filter records based on search query and selected column
        const filteredRecords = registrationData.filter((data) => {
            const searchValue = data[this.state.searchColumn];
            if (searchValue === undefined || searchValue === null) {
                return false;
            }
            if (Array.isArray(searchValue)) {
                return searchValue.some(item => item.toString().toLowerCase().includes(this.state.searchQuery.toLowerCase()));
            }
            return searchValue.toString().toLowerCase().includes(this.state.searchQuery.toLowerCase());
        });

        // Sort records based on sort column and order
        const sortedRecords = filteredRecords.sort((a, b) => {
            const sortValueA = a[sortColumn];
            const sortValueB = b[sortColumn];
            if (sortOrder === 'asc') {
                return sortValueA > sortValueB ? 1 : -1;
            }
            return sortValueA < sortValueB ? 1 : -1;
        });

        // Calculate pagination details based on sorted and filtered records
        const totalRecords = sortedRecords.length;
        const totalPages = Math.ceil(totalRecords / recordsPerPage);
        const startIndex = (currentPage - 1) * recordsPerPage;
        const endIndex = Math.min(startIndex + recordsPerPage, totalRecords);

        // Get the current page records from sorted and filtered records
        const currentRecords = sortedRecords.slice(startIndex, endIndex);

        return (
            <Spinner active={this.props.isBusyCommunicating}>
                <div className="container-main">
                    <Typography variant="h6" gutterBottom>
                        Finance Module
                    </Typography>



                    <div style={{ marginBottom: '20px' }}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={this.syncRegistrationData}
                            disabled={true}
                        >
                            Sync Registration Data
                        </Button>

                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => this.exportTableToExcel()}
                            style={{ marginLeft: '10px' }}
                        >
                            Export to Excel
                        </Button>
                    </div>

                    {!isLoading ? (
                        <>
                            <div style={{ margin: '20px 0', textAlign: 'center' }}>
                                <Typography>
                                    Page {currentPage} of {totalPages} ({startIndex + 1} - {endIndex} of {totalRecords} records)
                                </Typography>

                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => this.changePage(currentPage - 1)}
                                    disabled={currentPage === 1}
                                    style={{ marginRight: '10px' }}
                                >
                                    Previous
                                </Button>

                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => this.changePage(currentPage + 1)}
                                    disabled={currentPage === totalPages}
                                >
                                    Next
                                </Button>
                            </div>

                            {/* Search Section */}
                            <Box
                                style={{
                                    border: '1px solid lightgray',
                                    borderRadius: '4px',
                                    padding: '10px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginBottom: '20px'
                                }}
                            >
                                <TextField
                                    label="Search by"
                                    select
                                    value={this.state.searchColumn}
                                    onChange={(event) => this.setState({ searchColumn: event.target.value })}
                                    style={{ marginRight: '10px', width: '200px' }} // Adjust width as needed
                                >
                                    <MenuItem value="orgId">Org ID</MenuItem>
                                    <MenuItem value="dates">Dates</MenuItem>
                                    <MenuItem value="paymentOption">Payment Option</MenuItem>
                                    <MenuItem value="hubAmount">Hub Amount</MenuItem>
                                    <MenuItem value="hubComment">Hub Comment</MenuItem>
                                    <MenuItem value="zabihatCount">Zabihat Count</MenuItem>
                                    <MenuItem value="zabihatMonths">Zabihat Months</MenuItem>
                                    <MenuItem value="registrationAccepted">Registration Accepted</MenuItem>
                                    <MenuItem value="finalHubAmount">Final Hub Amount</MenuItem>
                                    <MenuItem value="financeComment">Finance Comment</MenuItem>
                                    <MenuItem value="firstName">First Name</MenuItem>
                                    <MenuItem value="lastName">Last Name</MenuItem>
                                    <MenuItem value="mobileNumber">Mobile Number</MenuItem>
                                    <MenuItem value="registrationFollowUpBy">Registration Follow Up By</MenuItem>
                                    <MenuItem value="registrationFollowUpStatus">Registration Follow Up Status</MenuItem>
                                    <MenuItem value="tallyUpdated">Tally Updated</MenuItem>
                                    <MenuItem value="updateIT">Update IT</MenuItem>
                                    <MenuItem value="zabihatTarget">Zabihat Target</MenuItem>
                                </TextField>
                                <TextField
                                    label="Search"
                                    value={searchQuery}
                                    onChange={this.handleSearchChange}
                                    style={{ marginRight: '10px', flexGrow: 1 }} // Flex grow to take the remaining space
                                />
                                <Button
                                    variant="contained"
                                    onClick={this.resetSearch}
                                    style={{ height: '56px' }} // Adjust height to match TextField
                                >
                                    Reset
                                </Button>
                            </Box>

                            {/* Rest of your render code... */}



                            <TableContainer component={Paper} style={{ marginTop: '20px' }}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell onClick={() => this.handleSort('orgId')}>
                                                Org ID {sortColumn === 'orgId' && (sortOrder === 'asc' ? '▲' : '▼')}
                                            </TableCell>
                                            <TableCell onClick={() => this.handleSort('firstName')}>
                                                First Name {sortColumn === 'firstName' && (sortOrder === 'asc' ? '▲' : '▼')}
                                            </TableCell>
                                            <TableCell onClick={() => this.handleSort('lastName')}>
                                                Last Name {sortColumn === 'lastName' && (sortOrder === 'asc' ? '▲' : '▼')}
                                            </TableCell>
                                            <TableCell onClick={() => this.handleSort('mobileNumber')}>
                                                Mobile Number {sortColumn === 'mobileNumber' && (sortOrder === 'asc' ? '▲' : '▼')}
                                            </TableCell>
                                            <TableCell onClick={() => this.handleSort('hubAmount')}>
                                                Hub Amount {sortColumn === 'hubAmount' && (sortOrder === 'asc' ? '▲' : '▼')}
                                            </TableCell>
                                            <TableCell onClick={() => this.handleSort('hubComment')}>
                                                Hub Comment {sortColumn === 'hubComment' && (sortOrder === 'asc' ? '▲' : '▼')}
                                            </TableCell>
                                            <TableCell onClick={() => this.handleSort('finalHubAmount')} style={{ backgroundColor: 'lightgrey' }}>
                                                Final Hub Amount {sortColumn === 'finalHubAmount' && (sortOrder === 'asc' ? '▲' : '▼')}
                                            </TableCell>
                                            <TableCell onClick={() => this.handleSort('dates')} style={{ display: 'none' }}>
                                                Dates {sortColumn === 'dates' && (sortOrder === 'asc' ? '▲' : '▼')}
                                            </TableCell>
                                            <TableCell onClick={() => this.handleSort('paymentOption')}>
                                                Payment Option {sortColumn === 'paymentOption' && (sortOrder === 'asc' ? '▲' : '▼')}
                                            </TableCell>
                                            <TableCell onClick={() => this.handleSort('zabihatCount')}>
                                                Zabihat Count {sortColumn === 'zabihatCount' && (sortOrder === 'asc' ? '▲' : '▼')}
                                            </TableCell>
                                            <TableCell onClick={() => this.handleSort('zabihatTarget')}>Zabihat Target {sortColumn === 'zabihatTarget' && (sortOrder === 'asc' ? '▲' : '▼')}</TableCell>
                                            <TableCell onClick={() => this.handleSort('zabihatMonths')} style={{ display: 'none' }}>
                                                Zabihat Months {sortColumn === 'zabihatMonths' && (sortOrder === 'asc' ? '▲' : '▼')}
                                            </TableCell>
                                            <TableCell onClick={() => this.handleSort('registrationFollowUpBy')} style={{ backgroundColor: 'lightgrey' }}>Registration Follow Up By {sortColumn === 'registrationFollowUpBy' && (sortOrder === 'asc' ? '▲' : '▼')}</TableCell>
                                            <TableCell onClick={() => this.handleSort('registrationFollowUpStatus')} style={{ backgroundColor: 'lightgrey' }}>Registration Follow Up Status {sortColumn === 'registrationFollowUpStatus' && (sortOrder === 'asc' ? '▲' : '▼')}</TableCell>
                                            <TableCell onClick={() => this.handleSort('tallyUpdated')} style={{ backgroundColor: 'lightgrey' }}>Tally Updated {sortColumn === 'tallyUpdated' && (sortOrder === 'asc' ? '▲' : '▼')}</TableCell>
                                            <TableCell onClick={() => this.handleSort('updateIT')} style={{ backgroundColor: 'lightgrey' }}>Update IT {sortColumn === 'updateIT' && (sortOrder === 'asc' ? '▲' : '▼')}</TableCell>
                                            <TableCell onClick={() => this.handleSort('financeComment')} style={{ backgroundColor: 'lightgrey' }}>
                                                Finance Comment {sortColumn === 'financeComment' && (sortOrder === 'asc' ? '▲' : '▼')}
                                            </TableCell>
                                            <TableCell onClick={() => this.handleSort('registrationAccepted')} style={{ backgroundColor: 'lightgrey' }}>
                                                Registration Accepted {sortColumn === 'registrationAccepted' && (sortOrder === 'asc' ? '▲' : '▼')}
                                            </TableCell>

                                            <TableCell>Actions</TableCell>
                                        </TableRow>

                                    </TableHead>
                                    <TableBody>
                                        {currentRecords.map((data: RegistrationData, index: number) => (
                                            <TableRow key={index}>
                                                <TableCell>{data.orgId}</TableCell>
                                                <TableCell>{data.firstName}</TableCell>
                                                <TableCell>{data.lastName}</TableCell>
                                                <TableCell>{data.mobileNumber}</TableCell>
                                                <TableCell>{data.hubAmount}</TableCell>
                                                <TableCell>{data.hubComment}</TableCell>
                                                <TableCell style={{ backgroundColor: 'lightgrey' }}>{data.finalHubAmount !== null ? data.finalHubAmount : ''}</TableCell>
                                                <TableCell style={{ display: 'none' }}>
                                                    {data.dates.map((dateEntry: DateEntry, idx: number) => (
                                                        <div key={idx}>
                                                            {dateEntry.value} (Count: {dateEntry.count})
                                                        </div>
                                                    ))}
                                                </TableCell>
                                                <TableCell>{data.paymentOption}</TableCell>
                                                <TableCell>{data.zabihatCount}</TableCell>
                                                <TableCell>{data.zabihatTarget}</TableCell>
                                                <TableCell style={{ display: 'none' }}>{data.zabihatMonths.join(', ')}</TableCell>
                                                <TableCell style={{ backgroundColor: 'lightgrey' }}>{data.registrationFollowUpBy}</TableCell>
                                                <TableCell style={{ backgroundColor: 'lightgrey' }}>{data.registrationFollowUpStatus}</TableCell>
                                                <TableCell style={{ backgroundColor: 'lightgrey' }}>{data.tallyUpdated.toString()}</TableCell>
                                                <TableCell style={{ backgroundColor: 'lightgrey' }}>{data.updateIT.toString()}</TableCell>
                                                <TableCell style={{ backgroundColor: 'lightgrey' }}>{data.financeComment}</TableCell>
                                                <TableCell style={{ backgroundColor: 'lightgrey' }}>
                                                    {data.registrationAccepted.toString() === 'true' ? 'Accepted' : 'Not Accepted'}
                                                </TableCell>

                                                <TableCell>
                                                    <IconButton onClick={() => this.handleDeleteDialogOpen(data.orgId)}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                    <IconButton
                                                        aria-label="Edit"
                                                        onClick={() => this.handleEditButtonClick(data.orgId)}
                                                    >
                                                        <EditIcon fontSize="small" />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            {/* Pagination Controls at the Bottom */}
                            <div style={{ marginTop: '20px', textAlign: 'center' }}>
                                <Typography>
                                    Page {currentPage} of {totalPages} ({startIndex + 1} - {endIndex} of {totalRecords} records)
                                </Typography>

                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => this.changePage(currentPage - 1)}
                                    disabled={currentPage === 1}
                                    style={{ marginRight: '10px' }}
                                >
                                    Previous
                                </Button>

                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => this.changePage(currentPage + 1)}
                                    disabled={currentPage === totalPages}
                                >
                                    Next
                                </Button>
                            </div>

                            <Dialog open={this.state.isDeleteDialogOpen} onClose={this.handleDeleteDialogClose}>
                                <DialogTitle>Confirm Deletion</DialogTitle>
                                <DialogContent>
                                    <Typography>Type "delete" to confirm the deletion:</Typography>
                                    <TextField
                                        value={this.state.deleteConfirmationInput}
                                        onChange={this.handleDeleteConfirmationChange}
                                        fullWidth
                                        margin="normal"
                                    />
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={this.handleDeleteDialogClose} color="primary">
                                        Cancel
                                    </Button>
                                    <Button
                                        onClick={this.confirmDelete}
                                        color="secondary"
                                        disabled={this.state.deleteConfirmationInput !== 'delete'}
                                    >
                                        Delete
                                    </Button>
                                </DialogActions>
                            </Dialog>


                            <Dialog open={isDialogOpen} onClose={this.handleDialogClose}>
                                <DialogTitle>Edit Registration Data</DialogTitle>
                                <DialogContent>
                                    {editingId && registrationData.find((data) => data.orgId === editingId) && (
                                        <form onSubmit={this.handleFormSubmit}>
                                            <TextField
                                                label="Jamaat Id"
                                                value={
                                                    editedData.orgId !== undefined
                                                        ? editedData.orgId
                                                        : registrationData.find((data) => data.orgId === editingId).orgId
                                                }
                                                onChange={(event) => { }

                                                }
                                                fullWidth
                                                margin="normal"
                                                disabled
                                            />
                                            <TextField
                                                label="First Name"
                                                value={
                                                    editedData.firstName !== undefined
                                                        ? editedData.firstName
                                                        : registrationData.find((data) => data.orgId === editingId).firstName
                                                }
                                                onChange={(event) => { }

                                                }
                                                fullWidth
                                                margin="normal"
                                                disabled
                                            />
                                            <TextField
                                                label="Last Name"
                                                value={
                                                    editedData.lastName !== undefined
                                                        ? editedData.lastName
                                                        : registrationData.find((data) => data.orgId === editingId).lastName
                                                }
                                                onChange={(event) => { }

                                                }
                                                fullWidth
                                                margin="normal"
                                                disabled
                                            />
                                            <TextField
                                                label="Mobile Number"
                                                value={
                                                    editedData.mobileNumber !== undefined
                                                        ? editedData.mobileNumber
                                                        : registrationData.find((data) => data.orgId === editingId).mobileNumber
                                                }
                                                onChange={(event) => { }

                                                }
                                                fullWidth
                                                margin="normal"
                                                disabled
                                            />
                                            <TextField
                                                label="Hub Amount"
                                                value={
                                                    editedData.hubAmount !== undefined
                                                        ? editedData.hubAmount
                                                        : registrationData.find((data) => data.orgId === editingId).hubAmount
                                                }
                                                onChange={(event) =>
                                                    this.handleInputChange(event, 'hubAmount')
                                                }
                                                fullWidth
                                                margin="normal"
                                                disabled
                                            />
                                            <TextField
                                                label="Hub Comment"
                                                value={
                                                    editedData.hubComment !== undefined
                                                        ? editedData.hubComment
                                                        : registrationData.find((data) => data.orgId === editingId).hubComment
                                                }
                                                onChange={(event) =>
                                                    this.handleInputChange(event, 'hubComment')
                                                }
                                                fullWidth
                                                margin="normal"
                                            />
                                            <TextField
                                                label="Final Hub Amount"
                                                value={
                                                    editedData.finalHubAmount !== undefined
                                                        ? editedData.finalHubAmount
                                                        : registrationData.find((data) => data.orgId === editingId).finalHubAmount || ''
                                                }
                                                onChange={(event) =>
                                                    this.handleInputChange(event, 'finalHubAmount')
                                                }
                                                fullWidth
                                                margin="normal"
                                            />
                                            <TextField
                                                label="Dates"
                                                value={
                                                    editedData.dates !== undefined
                                                        ? editedData.dates.map((date) => `${date.value} (Count: ${date.count})`).join(', ')
                                                        : registrationData.find((data) => data.orgId === editingId).dates.map((date) => `${date.value} (Count: ${date.count})`).join(', ')
                                                }
                                                onChange={(event) => { }}
                                                disabled
                                                fullWidth
                                                margin="normal"

                                            />
                                            <Typography variant="subtitle1" gutterBottom>
                                                Payment Option
                                            </Typography>
                                            <Select
                                                name="paymentOption"
                                                id="paymentOption"
                                                value={
                                                    editedData.paymentOption !== undefined
                                                        ? editedData.paymentOption
                                                        : registrationData.find((data) => data.orgId === editingId).paymentOption
                                                }
                                                onChange={(event) =>
                                                    this.handleInputChange(event, 'paymentOption')
                                                }
                                                displayEmpty
                                                fullWidth
                                                variant="outlined"
                                            >
                                                <MenuItem value="" disabled>Select Payment Method</MenuItem> {[{ label: 'One Cheque', value: 'onecheque' }, { label: 'Two Cheques', value: 'twocheque' }, { label: 'Qardan Hasana Application', value: 'qh' },].map((method, i) => (<MenuItem key={i} value={method.value}> {method.label} </MenuItem>))}                                            </Select>
                                            <Typography variant="subtitle1" gutterBottom>
                                                Zabihat Count
                                            </Typography>
                                            <Select
                                                value={
                                                    editedData.zabihatCount !== undefined
                                                        ? editedData.zabihatCount
                                                        : registrationData.find((data) => data.orgId === editingId).zabihatCount !== undefined &&
                                                            registrationData.find((data) => data.orgId === editingId).zabihatCount !== null
                                                            ? registrationData.find((data) => data.orgId === editingId).zabihatCount
                                                            : ""
                                                }
                                                onChange={(event) =>
                                                    this.handleInputChange(event, 'zabihatCount')
                                                }
                                                name="zabihatCount"
                                                id="zabihatCount"
                                                displayEmpty
                                                fullWidth
                                                variant="outlined"
                                            >
                                                <MenuItem value="" disabled>Select Zabihat Count</MenuItem>
                                                {Array.from({ length: 11 }, (_, index) => (
                                                    <MenuItem key={index} value={index}>
                                                        {index}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            {editedData.zabihatCount > 0 && (
                                                <>
                                                    <Typography variant="h6">Select preferred Zabihat month(s):</Typography>
                                                    <Grid container direction="column" spacing={2}>
                                                        {Array.from({ length: editedData.zabihatCount }).map((_, index) => (
                                                            <Grid item key={index}>
                                                                <FormControl fullWidth variant="outlined">
                                                                    <Select
                                                                        value={
                                                                            Array.isArray(editedData.zabihatMonths) && editedData.zabihatMonths[index]
                                                                                ? editedData.zabihatMonths[index]
                                                                                : registrationData.find((data) => data.orgId === editingId).zabihatMonths[index] || ''
                                                                        }
                                                                        onChange={(event) => this.handleMonthChange(event, index)}
                                                                        displayEmpty
                                                                    >
                                                                        <MenuItem value="" disabled>Select Month</MenuItem>
                                                                        {[
                                                                            'Moharram', 'Safar', 'Rabi-ul-Awwal', 'Rabi-ul-Akhar', 'Jamadi-ul-Awwal', 'Jamadi-ul-Akhar',
                                                                            'Rajab', 'Shabaan', 'Ramadaan', 'Shawwal', 'Zilqad', 'Zilhaj',
                                                                        ].map((month, i) => (
                                                                            <MenuItem key={i} value={month}>
                                                                                {month}
                                                                            </MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                </FormControl>
                                                                {this.state.validationErrors.zabihatMonths && <span className="error-message">{this.state.validationErrors.zabihatMonths}</span>}

                                                            </Grid>
                                                        ))}
                                                    </Grid>
                                                </>
                                            )}
                                            <TextField
                                                label="Zabihat Target"
                                                value={
                                                    editedData.zabihatTarget !== undefined
                                                        ? editedData.zabihatTarget
                                                        : registrationData.find((data) => data.orgId === editingId).zabihatTarget || 0
                                                }
                                                onChange={(event) =>
                                                    this.handleInputChange(event, 'zabihatTarget')
                                                }
                                                fullWidth
                                                margin="normal"
                                                disabled
                                            />
                                            <TextField
                                                label="Registration Follow Up By"
                                                select
                                                value={
                                                    editedData.registrationFollowUpBy !== undefined
                                                        ? editedData.registrationFollowUpBy
                                                        : registrationData.find((data) => data.orgId === editingId).registrationFollowUpBy || 'None'
                                                }
                                                onChange={(event) =>
                                                    this.handleInputChange(event, 'registrationFollowUpBy')
                                                }
                                                fullWidth
                                                margin="normal"
                                            >
                                                <MenuItem value="None">None</MenuItem>
                                                <MenuItem value="Mushtaq_Baldiwala">Mushtaq Baldiwala</MenuItem>
                                                <MenuItem value="Hatim_Mazahir">Hatim Mazahir</MenuItem>
                                                <MenuItem value="Yusuf_Rajkotwala">Yusuf Rajkotwala</MenuItem>
                                                <MenuItem value="Ali_Asghar_Khokhar">Ali Asghar Khokhar</MenuItem>
                                                <MenuItem value="Murtaza_Bootwala">Murtaza Bootwala</MenuItem>
                                                <MenuItem value="Muslim_Mohammedbhai">Muslim Mohammedbhai</MenuItem>
                                                <MenuItem value="Murtaza_Patanwala">Murtaza Patanwala</MenuItem>

                                            </TextField>
                                            <TextField
                                                label="Registration Follow Up Status"
                                                select
                                                value={
                                                    editedData.registrationFollowUpStatus !== undefined
                                                        ? editedData.registrationFollowUpStatus
                                                        : registrationData.find((data) => data.orgId === editingId).registrationFollowUpStatus || 'None'
                                                }
                                                onChange={(event) =>
                                                    this.handleInputChange(event, 'registrationFollowUpStatus')
                                                }
                                                fullWidth
                                                margin="normal"

                                            >
                                                <MenuItem value="None">None</MenuItem>
                                                <MenuItem value="First_Followup_Call">First Followup Call</MenuItem>
                                                <MenuItem value="Second_Followup_Call">Second Followup Call</MenuItem>
                                                <MenuItem value="Third_Followup_Call">Third Followup Call</MenuItem>
                                                <MenuItem value="No_Response">No Response</MenuItem>

                                            </TextField>
                                            <TextField
                                                label="Tally Updated"
                                                select
                                                value={
                                                    editedData.tallyUpdated !== undefined
                                                        ? editedData.tallyUpdated.toString()
                                                        : registrationData.find((data) => data.orgId === editingId).tallyUpdated.toString()
                                                }
                                                onChange={(event) =>
                                                    this.handleInputChange(event, 'tallyUpdated')
                                                }
                                                fullWidth
                                                margin="normal"
                                            >
                                                <MenuItem value="true">Yes</MenuItem>
                                                <MenuItem value="false">No</MenuItem>
                                            </TextField>
                                            <TextField
                                                label="Update IT"
                                                select
                                                value={
                                                    editedData.updateIT !== undefined
                                                        ? editedData.updateIT.toString()
                                                        : registrationData.find((data) => data.orgId === editingId).updateIT.toString()
                                                }
                                                onChange={(event) =>
                                                    this.handleInputChange(event, 'updateIT')
                                                }
                                                fullWidth
                                                margin="normal"
                                            >
                                                <MenuItem value="true">Yes</MenuItem>
                                                <MenuItem value="false">No</MenuItem>
                                            </TextField>
                                            <TextField
                                                label="Finance Comment"
                                                value={
                                                    editedData.financeComment !== undefined
                                                        ? editedData.financeComment
                                                        : registrationData.find((data) => data.orgId === editingId).financeComment || ''
                                                }
                                                onChange={(event) =>
                                                    this.handleInputChange(event, 'financeComment')
                                                }
                                                fullWidth
                                                margin="normal"
                                            />
                                            <TextField
                                                label="Registration Accepted"
                                                select
                                                value={
                                                    editedData.registrationAccepted !== undefined
                                                        ? editedData.registrationAccepted
                                                        : registrationData.find((data) => data.orgId === editingId).registrationAccepted.toString()
                                                }
                                                onChange={(event) =>
                                                    this.handleInputChange(event, 'registrationAccepted')
                                                }
                                                fullWidth
                                                margin="normal"
                                            >
                                                <MenuItem value="true">Yes</MenuItem>
                                                <MenuItem value="false">No</MenuItem>
                                            </TextField>
                                            <DialogActions>
                                                <Button type="submit" color="primary" variant="contained">
                                                    Save
                                                </Button>
                                                <Button
                                                    color="secondary"
                                                    variant="contained"
                                                    onClick={this.handleDialogClose}
                                                >
                                                    Cancel
                                                </Button>
                                            </DialogActions>
                                        </form>
                                    )}
                                </DialogContent>
                            </Dialog>



                        </>
                    ) : (
                        <Spinner active={true}>Loading...</Spinner>
                    )}
                </div>
                <Dialog
                    open={isSuccessDialogOpen}
                    onClose={this.handleSuccessDialogClose}
                    aria-labelledby="success-dialog-title"
                >
                    <DialogTitle id="success-dialog-title">Success</DialogTitle>
                    <DialogContent>
                        <Typography>{this.state.successMessage}</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleSuccessDialogClose} color="primary">
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={isErrorDialogOpen}
                    onClose={this.handleErrorDialogClose}
                    aria-labelledby="error-dialog-title"
                >
                    <DialogTitle id="error-dialog-title">Error</DialogTitle>
                    <DialogContent>
                        <Typography>{this.state.errorMessage}</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleErrorDialogClose} color="primary">
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>


            </Spinner>
        );
    }
}

const mapStateToProps = (state: AppState) => ({
    authenticated: state.authentication.authenticated,
    registrationData: state.registrationData,
    isBusyCommunicating: state.isBusyCommunicating,
});

const mapDispatchToProps = {
    updateFinanceRegistrationData: registrationDataAction.updateFinanceRegistrationData,
    getRegistrationData: registrationDataAction.getRegistrationData,
    deleteRegistrationData: registrationDataAction.deleteRegistrationData,
    syncRegistrationData: registrationDataAction.syncRegistrationData,
};

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(FinanceModule));